import * as securityService from './service/security.service';
import textTransform from '@/helpers/textTransform.js';

const state = {
	usersActive: [],
	managements: [],
	costCenters: [],
	isPendingUsers: false,
};

const mutations = {
	SET_USERS_ACTIVE(state, payload) {
		state.usersActive = [...payload];
	},
	SET_MANAGEMENTS(state, payload) {
		state.managements = [...payload];
	},
	SET_COST_CENTERS(state, payload) {
		state.costCenters = [...payload];
	},
	SET_IS_PENDING_USERS(state, payload) {
		state.isPendingUsers = payload;
	},
};

const actions = {
	getUsersActive({ commit, state }, { success, fail } = {}) {
		if (!state.isPendingUsers) {
			if (Array.isArray(state.usersActive) && state.usersActive.length == 0) {
				commit('SET_IS_PENDING_USERS', true);
				commit('SET_USERS_ACTIVE', []);
				securityService.usersActive(
					(response) => {
						let users = response?.data?.data;
						if (Array.isArray(users)) {
							users = users.map((user) => {
								return {
									...user,
									fullName: textTransform(user.fullName, 'capitalize'),
								};
							});
						}
						commit('SET_USERS_ACTIVE', users || []);
						commit('SET_IS_PENDING_USERS', false);
						success && success(response);
					},
					(response) => {
						commit('SET_IS_PENDING_USERS', false);
						fail && fail(response);
					}
				);
			}
		}
	},
	getManagements({ commit }, { success, fail, params } = {}) {
		commit('SET_MANAGEMENTS', []);
		if (params) {
			securityService.getManagements(
				(response) => {
					let costCenters = [];
					const managements = response.data.data.managements;
					managements.forEach((management) => {
						management.costCenters.forEach((ceco) => {
							costCenters.push({
								...ceco,
								management_id: management?.id,
								name: `${ceco.name} - ${ceco.pep_code}`,
							});
						});
					});
					commit('SET_MANAGEMENTS', managements);
					commit('SET_COST_CENTERS', costCenters);
					success && success(response);
				},
				(response) => {
					fail && fail(response);
				},
				params
			);
		}
	},
	cleanManagements({ commit }) {
		commit('SET_MANAGEMENTS', []);
		commit('SET_COST_CENTERS', []);
	},
};

const getters = {
	getUsers(state) {
		let items = [];
		if (Array.isArray(state.usersActive)) {
			items = state.usersActive.map((user) => {
				return { ...user, name: user.fullName };
			});
		}
		return items || [];
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
