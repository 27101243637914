import { http } from '../../../api/http-common';

const getFormat = async () => {
	try {
		const { data } = await http.get('incident-report/format');
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getIncident = async (params) => {
	try {
		const { data } = await http.get('incident-report/' + params?.id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listIncident = async (params) => {
	try {
		const { data } = await http.get('incident-report', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveIncident = async (payload) => {
	try {
		const { data } = await http.post('incident-report', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateIncident = async ({ incidentId, payload }) => {
	try {
		const { data } = await http.put(`incident-report/${incidentId}`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveFiles = async (payload) => {
	try {
		const { data } = await http.post('incident-report/file', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getIncidentAssign = async () => {
	try {
		const { data } = await http.get('incident-report-assign');
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};
const saveIncidentAssign = async (payload) => {
	try {
		const { data } = await http.post('incident-report-assign', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const approveIncident = async (payload) => {
	try {
		const { data } = await http.post('incident-report/approve', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	listIncident,
	saveIncident,
	getFormat,
	saveFiles,
	getIncident,
	updateIncident,
	getIncidentAssign,
	saveIncidentAssign,
	approveIncident,
};
