import * as toolService from './service/tool.service';

const state = {
	tool: null,
	tools: [],
};

const mutations = {
	SET_TOOL(state, payload) {
		state.tool = payload;
	},

	SET_TOOLS(state, payload) {
		state.tools = payload;
	},
};

const actions = {
	/*list({ commit }, { success, fail, params } = {}) {
		commit('SET_DATA', []);
		toolService.list(
			(response) => {
				commit('SET_DATA', response.data.inspections);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			params
		);
	},
	find({ commit }, { success, fail, id } = {}) {
		commit('SET_INSPECTION', null);
		toolService.find(
			(response) => {
				commit('SET_INSPECTION', response.data.data);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			id
		);
	},
	getReport({ commit }, { success, fail, id, filters } = {}) {
		commit('SET_REPORT', null);
		toolService.getReport(
			(response) => {
				commit('SET_REPORT', response.data.inspection);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			id,
			filters
		);
	},
	updateInputHeader({ state, commit }, data) {
		const { value, id } = data;
		let headers = state.inspection?.headers;
		const index = headers.findIndex((header) => header.id === id);
		if (index >= 0) {
			headers[index].value = value;
			commit('SET_INSPECTION', { ...state.inspection, headers });
		}
	},*/

	async listTools({ commit }, payload = null) {
		try {
			const response = await toolService.list(payload);
			commit('SET_TOOLS', response?.tools || []);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async saveTool({ dispatch }, payload) {
		try {
			const response = await toolService.save(payload);
			dispatch('listTools');
			return response;
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

const getters = {
	getHeaderById: (state) => (id) => {
		const { headers } = state.inspection;
		if (headers && Array.isArray(headers)) {
			const { value } = headers.find((header) => header.id === id);
			return value;
		} else {
			return null;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
