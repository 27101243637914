<template>
	<v-app>
		<nav-bar @switchDrawer="activeDrawer" :moduleList="allowedModules" />
		<v-main class="b-grey">
			<router-view />
		</v-main>
		<v-navigation-drawer v-model="drawer" fixed temporary>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title v-if="user">{{ user.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense>
				<v-list-group
					v-for="item in allowedModules"
					:key="item.title"
					v-model="item.active"
					:prepend-icon="item.action"
					no-action
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item
						v-for="child in item.items"
						:key="child.title"
						:to="child.to"
						link
					>
						<v-list-item-content>
							<v-list-item-title v-text="child.title"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn color="primary" class="text-none" block @click="onLogout()"
						>Cerrar Sesión</v-btn
					>
				</div>
			</template>
		</v-navigation-drawer>
		<snack-notification />
	</v-app>
</template>

<script>
import SnackNotification from '../components/global/SnackNotification.vue';
import NavBar from '../components/nav';
export default {
	name: 'DashboardLayout',

	data: () => ({
		drawer: null,
		items: [
			{
				title: 'Inspecciones HSEQ',
				action: 'mdi-folder-text-outline',
				to: '/dashboard/inspections',
				moduleName: 'inspeccion',
				pageName: 'listaInspecciones',
				showInNav: true,
				childRouteNames: [
					'InspectionsList',
					'InspectionReport',
					'InspectionsRegisterList',
					'InspectionsRegisterCreate',
					'InspectionsRegisterShow',
					'InspectionsRegisterDraft',
				],
				items: [
					{
						title: 'Inspecciones HSEQ',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/inspections',
					},
				],
			},
			{
				title: 'Revisiones',
				action: 'mdi-archive-eye-outline',
				to: '/dashboard/inspections/revision',
				moduleName: 'aprobacion',
				pageName: 'listaRegistros',
				showInNav: true,
				childRouteNames: [
					'InspectionsRegisterListRevision',
					'InspectionsRegisterRevision',
				],
				items: [
					{
						title: 'Revisiones',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/inspections/revision',
					},
				],
			},
			{
				title: 'Reporte de Fallas o Incidentes',
				action: 'mdi-archive-eye-outline',
				to: '/dashboard/incidents',
				moduleName: 'incidencia',
				pageName: 'listaIncidencias',
				showInNav: true,
				childRouteNames: [
					'IncidentList',
					'IncidentCreate',
					'IncidentShow',
					'IncidentDraft',
					'IncidentRevision',
				],
				items: [
					{
						title: 'Reporte de Fallas o Incidentes',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/incidents',
					},
				],
			},
			{
				title: 'Usuario',
				action: 'mdi-account',
				to: '/dashboard/user/permissions',
				showInNav: false,
				items: [
					{
						title: 'Permisos',
						action: 'mdi-account',
						to: '/dashboard/user/permissions',
					},
					{
						title: 'Cambiar Contraseña',
						action: 'mdi-account',
						to: { name: 'UserChangePassword' },
					},
				],
			},
		],
	}),
	computed: {
		allowedModules() {
			return this.getAllowedModules();
		},
		user() {
			return this.$store.state.auth.user || null;
		},
	},
	methods: {
		activeDrawer() {
			this.drawer = true;
		},
		getInitialName() {
			if (this.user?.name && this.user?.last_name_father) {
				const name = this.user?.name;
				const lastName = this.user?.last_name_father;
				return `${name[0]}${lastName[0]}`;
			}
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login' });
		},
		getAllowedModules() {
			const activities = this.$store.state.auth.activities || [];
			let modulesList = this.items || [];

			modulesList = modulesList.filter((item) => {
				let found = false;
				if (Array.isArray(activities)) {
					found = activities.some((activity) => {
						if (item.moduleName && item.pageName) {
							return (
								activity.module_name == item.moduleName &&
								activity.page_name == item.pageName
							);
						} else {
							return true;
						}
					});
				}
				return found;
			});
			return modulesList;
		},
	},
	components: {
		NavBar,
		SnackNotification,
	},
};
</script>
