import * as noteQuarterServiceSerive from './service/note_quarter_service.service';

const state = {
  noteQuarterService: null,
  notesQuarterService: [],
  success: false
};

const mutations = {
  SET_NOTES_QUARTER_SERVICE(state, payload) {
    state.notesQuarterService = [...payload];
  },
  SET_NOTE_QUARTER_SERVICE(state, payload) {
    state.noteQuarterService = payload;
  },
  SET_SUCCESS(state, payload) {
    state.success = payload
  }
};

const actions = {
  resetQuarters({ commit }){
    commit('SET_NOTES_QUARTER_SERVICE',[]);
  },
  resetQuarter({ commit }){
    commit('SET_NOTE_QUARTER_SERVICE',null);
  },
  list({ commit }, { success, fail, params } = {}) {
    commit('SET_NOTES_QUARTER_SERVICE',[])
    noteQuarterServiceSerive.list(
      (response) => {
        commit('SET_NOTES_QUARTER_SERVICE', response.data.data);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      params
    );
  },
  find({ commit }, { success, fail, id } = {}) {
    commit('SET_NOTE_QUARTER_SERVICE', null);
    noteQuarterServiceSerive.find(
      (response) => {
        commit('SET_NOTE_QUARTER_SERVICE', response.data.noteQuarterService);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      id
    );
  },
  create({ commit }, { success, fail, data } = {}) {
    noteQuarterServiceSerive.create(
      (response) => {
        commit('SET_SUCCESS', true);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      data
    );
  }
};

const getters = {
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};