import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./plugins/axios";
import "./plugins/vuedatepicker";
import './assets/scss/main.scss';
import 'sweetalert2/src/sweetalert2.scss'

export const $EventBus = new Vue();

Vue.component('ValidateActivity', require('./components/global/ValidateActivity.vue').default);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
