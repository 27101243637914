import { http } from '../../../api/http-common';

const list = async (params) => {
	try {
		const { data } = await http.get('tool', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const save = async (payload) => {
	try {
		const { data } = await http.post('tool', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { list, save };
