import * as registerService from './service/register.service';

const state = {
	register: null,
	registers: [],
	registers_pagination: null,
	registers_revision: [],
	registers_revision_pagination: null,
	registers_inspection: null,
	success: false,
};

const mutations = {
	SET_REGISTER(state, payload) {
		state.register = payload;
	},
	SET_DATA(state, payload) {
		state.registers = [...payload];
	},
	SET_REGISTERS_PAGINATION(state, payload) {
		state.registers_pagination = payload;
	},
	SET_DATA_REVISION(state, payload) {
		state.registers_revision = [...payload];
	},
	SET_DATA_REVISION_PAGINATION(state, payload) {
		state.registers_revision_pagination = payload;
	},
	SET_SUCCESS(state, payload) {
		state.success = payload;
	},
	SET_REGISTERS_INSPECTION(state, payload) {
		state.registers_inspection = payload;
	},
};

const actions = {
	findById({ commit }, { success, fail, params } = {}) {
		commit('SET_REGISTER', null);
		registerService.findById(
			(response) => {
				commit('SET_REGISTER', response.data.data);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			params
		);
	},
	revision({ commit }, { success, fail, filters } = {}) {
		commit('SET_DATA_REVISION', []);
		commit('SET_DATA_REVISION_PAGINATION', null);
		registerService.revision(
			(response) => {
				commit('SET_DATA_REVISION', response.data.registers);
				commit(
					'SET_DATA_REVISION_PAGINATION',
					response?.data?.pagination || null
				);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			filters
		);
	},
	list({ commit }, { success, fail, id, filters } = {}) {
		commit('SET_DATA', []);
		commit('SET_REGISTERS_PAGINATION', null);
		registerService.list(
			(response) => {
				commit('SET_DATA', response.data.registers);
				commit('SET_REGISTERS_INSPECTION', response.data.inspection);
				commit('SET_REGISTERS_PAGINATION', response?.data?.pagination || null);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			id,
			filters
		);
	},
	updateStatus({ commit }, { success, fail, data } = {}) {
		registerService.updateStatus(
			(response) => {
				commit('SET_SUCCESS', true);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			data
		);
	},
	create({ commit }, { success, fail, data } = {}) {
		registerService.create(
			(response) => {
				commit('SET_SUCCESS', true);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			data
		);
	},
	update({ commit }, { success, fail, data } = {}) {
		registerService.update(
			(response) => {
				commit('SET_SUCCESS', true);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			data
		);
	},
	updateRegister({ commit }, { success, fail, data } = {}) {
		registerService.updateRegister(
			(response) => {
				commit('SET_SUCCESS', true);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			data
		);
	},
	draft({ commit }, { success, fail, data } = {}) {
		registerService.draft(
			(response) => {
				commit('SET_SUCCESS', true);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			data
		);
	},
	pdf({ commit }, { success, fail, id } = {}) {
		if (id) {
			commit('SET_SUCCESS', true);
			success && success(registerService.pdf(id));
		} else {
			fail && fail('');
		}
	},
	cleanRegisters({ commit }) {
		commit('SET_DATA', []);
		commit('SET_REGISTERS_INSPECTION', null);
		commit('SET_REGISTERS_PAGINATION', null);
	},
	cleanRegister({ commit }) {
		commit('SET_REGISTER', null);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
