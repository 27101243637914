import { http } from '../../../api/http-common';

const findByInspection = (success, fail, id) => {
  http
    .get('inspection-asign/'+id)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const saveAsign = (success, fail, data) => {
  http
    .post(`inspection-asign`, data)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

export { findByInspection, saveAsign }; 