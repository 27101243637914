import * as incidentService from './service/incident.service';

const state = {
	incidentFormat: null,
	evidenceFiles: [],
	incidents: [],
	incident: null,
	IncidentAssign: [],
	registers_pagination: null,
	incident_types: [],
	control_failure: [],
};

const mutations = {
	SET_INCIDENT_FORMAT(state, payload) {
		if (Array.isArray(payload?.fields) && payload?.fields?.length > 0) {
			let fields = [...payload.fields];
			fields.shift();
			payload.headers = payload?.fields[0]?.items || [];
			payload.bodies = fields || [];
		}
		state.incidentFormat = payload;
	},

	SET_INCIDENT(state, payload) {
		state.incident = payload;
	},

	SET_INCIDENTS(state, payload) {
		state.incidents = payload;
	},
	SET_REGISTERS_PAGINATION(state, payload) {
		state.registers_pagination = payload;
	},

	SET_EVIDENCE_FILES(state, payload) {
		state.evidenceFiles = payload;
	},

	SET_INCIDENT_FORMAT_VALUES(state, payload) {
		let fields = [...payload.fields];
		fields.shift();
		const headers = payload?.fields[0]?.items || [];
		payload.headers = headers.map((header) => {
			return { ...header, disabled: null };
		});

		fields = fields.map((body) => {
			if (body?.group) {
				const items = body.items.map((item) => {
					let value = [];
					let jsonParsed = {};
					let otherText = null;
					try {
						jsonParsed = JSON.parse(item.value);
						value = jsonParsed?.values;
						otherText = jsonParsed?.otherText || null;
					} catch (error) {}
					return { ...item, value, otherText };
				});
				return { ...body, items };
			} else {
				return body;
			}
		});
		payload.bodies = fields || [];

		state.incidentFormat = payload;
	},

	SET_INCIDENT_FORMAT_VALUES_DIRECT(state, payload) {
		state.incidentFormat = payload;
	},

	SET_INCIDENT_ASSIGN(state, payload) {
		state.IncidentAssign = payload;
	},

	SET_INCIDENT_TYPES(state, payload) {
		state.incident_types = payload;
	},

	SET_CONTROL_FAILURE(state, payload) {
		state.control_failure = payload;
	},
};

const actions = {
	async getIncident({ commit }, data) {
		try {
			const response = await incidentService.getIncident(data);
			commit('SET_INCIDENT', response?.data || null);
			if (data?.setValue) {
				commit('SET_INCIDENT_FORMAT_VALUES', response?.data);
			}
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveFiles({ commit }, data) {
		try {
			const response = await incidentService.saveFiles(data);
			commit('SET_EVIDENCE_FILES', response?.data || null);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getFormat({ commit }) {
		try {
			const response = await incidentService.getFormat();
			commit('SET_INCIDENT_FORMAT', response?.data || null);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveIncident(context, data) {
		try {
			const response = await incidentService.saveIncident(data);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateIncident(context, data) {
		try {
			const response = await incidentService.updateIncident(data);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listIncident({ commit }, data) {
		try {
			const response = await incidentService.listIncident(data);
			commit('SET_INCIDENTS', response?.incidentReports || []);
			commit('SET_REGISTERS_PAGINATION', response?.pagination || null);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getIncidentAssign({ commit }) {
		try {
			const response = await incidentService.getIncidentAssign();
			commit('SET_INCIDENT_ASSIGN', response?.incidentReportAssigns || []);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveIncidentAssign(context, data) {
		try {
			const response = await incidentService.saveIncidentAssign(data);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async approveIncident(context, data) {
		try {
			const response = await incidentService.approveIncident(data);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getIncidentTypes({ commit }) {
		try {
			const response = await incidentService.getFormat();
			let incidentTypes = response?.data?.fields[1]?.items[1]?.values || [];
			let controlFailure = response?.data?.fields[1]?.items[0]?.values || [];

			try {
				incidentTypes = JSON.parse(incidentTypes);
				controlFailure = JSON.parse(controlFailure);
			} catch (error) {
				incidentTypes = [];
				controlFailure = [];
			}
			if (Array.isArray(incidentTypes)) {
				commit('SET_INCIDENT_TYPES', incidentTypes || []);
			}
			if (Array.isArray(controlFailure)) {
				commit('SET_CONTROL_FAILURE', controlFailure || []);
			}
			return { ok: true, response: incidentTypes };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	updateInputHeader({ state, commit, dispatch }, data) {
		const { value, id, disabled } = data;
		let headers = [...state.incidentFormat?.headers];
		const index = headers.findIndex((header) => header.id === id);
		if (index >= 0) {
			headers[index] = { ...headers[index], value, disabled };
			commit('SET_INCIDENT_FORMAT_VALUES_DIRECT', {
				...state.incidentFormat,
				headers,
			});
			//TODO: listen change Manager
			if (headers[index].key == 'user_reporting') {
				console.log('here!');
				dispatch('listenInputHeaderManager', value);
			}
		}
	},

	listenInputHeaderManager({ state, commit, dispatch, rootState }, value) {
		let headers = state.incidentFormat?.headers;
		const indexService = headers.findIndex((header) => header.key === 'service');
		dispatch(
			'security/getManagements',
			{
				success: () => {
					const costCenters = rootState.security.costCenters;
					if (Array.isArray(costCenters)) {
						if (
							indexService >= 0 &&
							!costCenters.some(
								(ceco) => ceco.id == headers[indexService]?.value
							)
						) {
							headers[indexService] = {
								...headers[indexService],
								value: null,
							};
							commit('SET_INCIDENT_FORMAT_VALUES_DIRECT', {
								...state.incidentFormat,
								headers,
							});
						}
					}
				},
				params: { user_id: value },
			},
			{ root: true }
		);
	},

	cleanIncident({ commit }) {
		commit('SET_INCIDENT_FORMAT', null);
		commit('SET_EVIDENCE_FILES', []);
		commit('SET_INCIDENT', null);
		commit('SET_INCIDENT_TYPES', []);
		commit('SET_CONTROL_FAILURE', []);
	},

	cleanIncidents({ commit }) {
		commit('SET_INCIDENTS', []);
		commit('SET_INCIDENT_TYPES', []);
		commit('SET_CONTROL_FAILURE', []);
	},

	setIncidentValues({ commit }, payload) {
		commit('SET_INCIDENT_FORMAT_VALUES_DIRECT', payload);
	},
};

const getters = {
	getHeaderById: (state) => (id) => {
		const { headers } = state.incidentFormat;
		if (headers && Array.isArray(headers)) {
			const header = headers.find((header) => header.id === id);
			return header || null;
		} else {
			return null;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
