import * as inspectionAsignService from './service/inspection-asign.service';

const state = {
  inspectionAsigns: [],
  success: false
};

const mutations = {
  SET_INSPECTION_ASIGNS(state, payload) {
    state.inspectionAsigns = [...payload];
  },
  SET_SUCCESS(state, payload) {
    state.success = payload
  }
};

const actions = {
  findByInspection({ commit }, { success, fail, id } = {}) {
    commit('SET_INSPECTION_ASIGNS',[])
    inspectionAsignService.findByInspection(
      (response) => {
        commit('SET_INSPECTION_ASIGNS', response.data.inspectionAsigns);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      id
    );
  },
  saveAsign({ commit }, { success, fail, data } = {}) {
    inspectionAsignService.saveAsign(
      (response) => {
        commit('SET_SUCCESS', true);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      data
    );
  },
};

const getters = {
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};