import { http } from '../../../api/http-common';

// USERS
const usersActive = (success, fail) => {
	http.get(`user`)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

// MANAGEMENTS
const getManagements = (success, fail, params) => {
	http.get(`cost-center/${params?.user_id}`)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

export { usersActive, getManagements };
