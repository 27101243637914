<template>
  <v-snackbar v-model="snackbar" :color="status">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { $EventBus } from "@/main";
export default {
  data: () => ({
    snackbar: false,
    text: ``,
    status: ''
  }),
  mounted: function () {
    $EventBus.$on("showSnack", (status, message) => {
      this.snackbar = true;
      this.status = status;
      this.text = message;
    });
  },
};
</script>
