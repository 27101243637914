import { http } from '../../../api/http-common';

const list = (success, fail, params) => {
  http
    .get('inspection', {params})
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const find = (success, fail, id) => {
  http
    .get(/*'inspection/'+id*/ `register/${id}/format`)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const getReport = (success, fail, id, params) => {
  http
    .get('inspection/'+id+'/report', { params })
    .then((response) => success(response))
    .catch((response) => fail(response));
};

export { list, find, getReport }; 