import { http } from '../../../api/http-common';

const list = (success, fail, params) => {
  http
    .get('note_quarter_service', {params})
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const find = (success, fail, id) => {
  http
    .get('note_quarter_service/'+id)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

const create = (success, fail, data) => {
  http
    .post(`note_quarter_service`, data)
    .then((response) => success(response))
    .catch((response) => fail(response));
};

export { list, find, create }; 