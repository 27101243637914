import * as inspectionService from './service/inspection.service';

const state = {
	inspections: [],
	inspection: null,
	report: null,
};

const mutations = {
	SET_DATA(state, payload) {
		state.inspections = [...payload];
	},
	SET_INSPECTION(state, payload) {
		state.inspection = payload;
	},
	SET_REPORT(state, payload) {
		state.report = payload;
	},
};

const actions = {
	list({ commit }, { success, fail, params } = {}) {
		commit('SET_DATA', []);
		inspectionService.list(
			(response) => {
				commit('SET_DATA', response.data.inspections);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			params
		);
	},
	find({ commit }, { success, fail, id } = {}) {
		commit('SET_INSPECTION', null);
		inspectionService.find(
			(response) => {
				commit('SET_INSPECTION', response.data.data);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			id
		);
	},
	getReport({ commit }, { success, fail, id, filters } = {}) {
		commit('SET_REPORT', null);
		inspectionService.getReport(
			(response) => {
				commit('SET_REPORT', response.data.inspection);
				success && success(response);
			},
			(response) => {
				fail && fail(response);
			},
			id,
			filters
		);
	},
	updateInputHeader({ state, commit, dispatch }, data) {
		const { value, id, disabled } = data;
		let headers = state.inspection?.headers;
		const index = headers.findIndex((header) => header.id === id);
		if (index >= 0) {
			headers[index] = { ...headers[index], value, disabled };
			commit('SET_INSPECTION', { ...state.inspection, headers });
			//TODO: listen change Manager
			if (headers[index].key == 'manager') {
				dispatch('listenInputHeaderManager', value);
			}
		}
	},
	listenInputHeaderManager({ state, commit, dispatch, rootState }, value) {
		let headers = state.inspection?.headers;
		const indexService = headers.findIndex((header) => header.key === 'service');
		dispatch(
			'security/getManagements',
			{
				success: () => {
					const costCenters = rootState.security.costCenters;
					if (Array.isArray(costCenters)) {
						if (
							indexService >= 0 &&
							!costCenters.some(
								(ceco) => ceco.id == headers[indexService]?.value
							)
						) {
							headers[indexService] = {
								...headers[indexService],
								value: null,
							};
							commit('SET_INSPECTION', {
								...state.inspection,
								headers,
							});
						}
					}
				},
				params: { user_id: value },
			},
			{ root: true }
		);
	},
	updateInputBody({ state, commit }, data) {
		const { value, id } = data;
		let bodies = state.inspection?.bodies;
		bodies.forEach((group, iGroup) => {
			group.items.forEach((item, iItem) => {
				if (item.id == id) {
					bodies[iGroup].items[iItem].value = value;
					commit('SET_INSPECTION', { ...state.inspection, bodies });
				}
			});
		});
	},
	updateInputQuestion({ state, commit }, data) {
		const { value, id } = data;
		let bodyQuestions = state.inspection?.bodyQuestions;
		bodyQuestions.forEach((group, iGroup) => {
			group.items.forEach((item, iItem) => {
				if (item.id == id) {
					bodyQuestions[iGroup].items[iItem].value = value;
					commit('SET_INSPECTION', { ...state.inspection, bodyQuestions });
				}
			});
		});
	},
	updateInputImprovement({ state, commit }, data) {
		const { criticity, finding, id, body_id } = data;
		let improvements = state.inspection?.improvements;
		improvements.forEach((group, iGroup) => {
			group.items.forEach((item, iItem) => {
				if (item.id == id) {
					improvements[iGroup].items[iItem].criticity = criticity;
					improvements[iGroup].items[iItem].finding = finding;
					improvements[iGroup].items[iItem].body_id = body_id;
					commit('SET_INSPECTION', { ...state.inspection, improvements });
				}
			});
		});
	},
	cleanInspection({ commit }) {
		commit('SET_INSPECTION', null);
	},

	cleanReport({ commit }) {
		commit('SET_REPORT', null);
	},
};

const getters = {
	getHeaderById: (state) => (id) => {
		const { headers } = state.inspection;
		if (headers && Array.isArray(headers)) {
			const header = headers.find((header) => header.id === id);
			return header || null;
		} else {
			return null;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
