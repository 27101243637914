import { http } from '../../../api/http-common';
import { urlApi } from '../../../api/config';

const findById = (success, fail, params) => {
	const { inspectionId, registerId } = params;
	http.get(`register/${inspectionId}/${registerId}`)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const revision = (success, fail, params) => {
	http.get(`inspection/revision`, { params })
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const list = (success, fail, id, params) => {
	http.get(`register/${id}`, { params })
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const create = (success, fail, data) => {
	http.post(`register/${data?.register?.format}`, data)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const update = (success, fail, data) => {
	http.put(`register/${data?.register?.format}/${data?.register?.register_id}`, data)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const updateRegister = (success, fail, data) => {
	http.post(`register/${data?.register?.format}/status-update`, data)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const draft = (success, fail, data) => {
	http.post(`register/draft`, data)
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const updateStatus = (success, fail, data) => {
	http.post(`register/${data.format}/status-update`, {id: data.id})
		.then((response) => success(response))
		.catch((response) => fail(response));
};

const pdf = (id) => {
	return `${urlApi}/register/exportar/pdf/${id}`;
};

export {
	findById,
	list,
	revision,
	create,
	draft,
	pdf,
	updateStatus,
	update,
	updateRegister,
};
