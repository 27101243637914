import * as subprocessService from './service/subprocess.service';

const state = {
  subprocesses: []
};

const mutations = {
  SET_DATA(state, payload) {
    state.subprocesses = [...payload];
  }
};

const actions = {
  list({ commit }, { success, fail, query } = {}) {
    commit('SET_DATA',[])
    subprocessService.list(
      (response) => {
        commit('SET_DATA', response.data.subprocesses);
        success && success(response)
      },
      (response) => {
        fail && fail(response)
      },
      query
    );
  }
};

const getters = {
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};