import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout';
import isAuthenticatedGuard from '@/router/auth-guard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'ToLogin',
	},
	{
		path: '/',
		component: DefaultLayout,
		name: 'DefaultLayout',
		children: [
			{
				path: '/login',
				name: 'Login',
				component: () =>
					import(/* webpackChunkName: "login" */ '../views/Login.vue'),
			},
			{
				path: '/login/resetpassword',
				name: 'ResetPassword',
				component: () =>
					import(/* webpackChunkName: "ResetPassword" */ '../views/account/ResetPassword.vue'),
			},
			{
				path: '/recover/:token',
				name: 'RecoverPassword',
				component: () =>
					import(/* webpackChunkName: "RecoverPassword" */ '../views/account/RecoverPassword.vue'),
			},
		],
	},	
	{
		path: '/',
		component: DashboardLayout,
		name: 'Home',
		children: [
			
			{
				path: '/dashboard',
				name: 'Dashboard',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
			},
			{
				path: '/dashboard/inspections',
				name: 'InspectionsList',
				meta: { moduleName: 'inspeccion', pageName: 'listaInspecciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "inspections" */ '../views/dashboard/admin/inspections/list/InspectionsList.vue'
					),
			},
			{
				path: '/dashboard/inspections/:id/registers',
				name: 'InspectionsRegisterList',
				meta: { moduleName: 'inspeccion', pageName: 'listaRegistros' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "inspectionsregisterlist" */ '../views/dashboard/admin/inspections/registers/list/RegistersList.vue'
					),
			},
			{
				path: '/dashboard/inspections/:inspectionId/registers/create',
				name: 'InspectionsRegisterCreate',
				meta: { moduleName: 'inspeccion', pageName: 'crearRegistro' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "createdocumentinspections" */ '../views/dashboard/admin/inspections/registers/create/RegistersCreate.vue'
					),
			},
			{
				path: '/dashboard/inspections/:inspectionId/registers/:id',
				name: 'InspectionsRegisterShow',
				meta: { moduleName: 'inspeccion', pageName: 'mostrarRegistro' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "InspectionsRegisterShow" */ '../views/dashboard/admin/inspections/registers/show/RegistersShow.vue'
					),
			},
			{
				path: '/dashboard/inspections/:inspectionId/registers/:id/pdf',
				name: 'InspectionsRegisterPdf',
				meta: { moduleName: 'inspeccion', pageName: 'mostrarRegistro' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "InspectionsRegisterPdf" */ '../views/dashboard/admin/inspections/registers/pdf/RegistersPdf.vue'
					),
			},
			{
				path: '/dashboard/inspections/:inspectionId/registers/:id/draft',
				name: 'InspectionsRegisterDraft',
				meta: { moduleName: 'inspeccion', pageName: 'crearRegistro' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "InspectionsRegisterDraft" */ '../views/dashboard/admin/inspections/registers/draft/RegistersDraft.vue'
					),
			},
			// REPORT
			{
				path: '/dashboard/inspections/:id/report',
				name: 'InspectionReport',
				meta: { moduleName: 'inspeccion', pageName: 'mostrarReporte' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "inspectionreport" */ '../views/dashboard/admin/inspections/report/InspectionsReports.vue'
					),
			},
			// REVISION
			{
				path: '/dashboard/inspections/revision',
				name: 'InspectionsRegisterListRevision',
				meta: { moduleName: 'aprobacion', pageName: 'listaRegistros' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "inspectionsregisterlistrevision" */ '../views/dashboard/revision/inspections/registers/list/RegistersList.vue'
					),
			},
			{
				path: '/dashboard/inspections/:inspectionId/revision/:id',
				name: 'InspectionsRegisterRevision',
				meta: { moduleName: 'aprobacion', pageName: 'aprobarRegistro' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "InspectionsRegisterShow" */ '../views/dashboard/admin/inspections/registers/show/RegistersShow.vue'
					),
			},
			{
				path: '/dashboard/user/permissions',
				name: 'UserPermissions',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserPermissions" */ '@/views/dashboard/user/permissions/permissions.vue'
					),
			},
			//INCIDENTS
			{
				path: '/dashboard/incidents',
				name: 'IncidentList',
				meta: { moduleName: 'incidencia', pageName: 'listaIncidencias' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentList" */ '@/views/dashboard/incident/list/IncidentList.vue'
					),
			},
			{
				path: '/dashboard/incidents/create',
				name: 'IncidentCreate',
				meta: { moduleName: 'incidencia', pageName: 'crearIncidencia' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentCreate" */ '@/views/dashboard/incident/create/IncidentCreate.vue'
					),
			},
			{
				path: '/dashboard/incidents/show/:incidentId',
				name: 'IncidentShow',
				meta: { moduleName: 'incidencia', pageName: 'mostrarIncidencia' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentShow" */ '@/components/incident/modules/show/IncidentShow.vue'
					),
			},
			{
				path: '/dashboard/incidents/revision/:incidentId',
				name: 'IncidentRevision',
				meta: { moduleName: 'incidencia', pageName: 'aprobarIncidencia' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentRevision" */ '@/components/incident/modules/show/IncidentShow.vue'
					),
			},
			{
				path: '/dashboard/incidents/draft/:incidentId',
				name: 'IncidentDraft',
				meta: { moduleName: 'incidencia', pageName: 'crearIncidencia' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentDraft" */ '@/components/incident/modules/draft/IncidentDraft.vue'
					),
			},
			{
				path: '/dashboard/incidents/pdf/:incidentId',
				name: 'IncidentPdf',
				meta: { moduleName: 'incidencia', pageName: 'mostrarIncidencia' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentPdf" */ '@/components/incident/modules/pdf/IncidentPdf.vue'
					),
			},
			{
				path: '/dashboard/user/password',
				name: 'UserChangePassword',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserChangePassword" */ '@/views/dashboard/user/password/changePassword.vue'
					),
			},
			
			{
				path: '/401',
				name: 'NotAuthorized',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotAuthorized" */ '../views/401.vue'),
			},
			{
				path: '/:pathMatch(.*)*',
				name: 'NotFound',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
