import Vue from 'vue';
import Vuex from 'vuex';
// MODULES
import inspection from './modules/inspection';
import register from './modules/register';
import subprocess from './modules/subprocess';
import security from './modules/security';
import inspectionAsign from './modules/inspection-asign';
import noteQuarterService from './modules/note_quarter_service';
import tool from './modules/tool';
import auth from './modules/auth';
import incident from './modules/incident';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
	// TODO: strict: true,
	state: {},
	mutations: {},
	actions: {},
	modules: {
		inspection,
		register,
		subprocess,
		security,
		inspectionAsign,
		noteQuarterService,
		tool,
		auth,
		incident,
		user,
	},
});
